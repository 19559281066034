<template lang="pug">
  .hello
    vue-headful(title="歷史故事 - 自學地圖", description="本會的歷史故事傳承")
    h1 歷史故事
    Timeline(:timeline-items="timelineItems", :message-when-no-items="messageWhenNoItems")
 
</template>

<script>

import Timeline from 'timeline-vuejs'

export default {
  name: 'short',
  components: {
    Timeline
  },
  data: () => ({
    messageWhenNoItems: 'There are not items',
    timelineItems: [
      {
        from: new Date(1994, 8),
        title: '種籽親子實驗小學',
        description:
          '<img class="history" src="./img/seedling.png"/>台灣民主學校的先驅，由十個家庭共同創立。獨立教育工作者社群（以下簡稱社群）教師李雅卿為創辦人及首任苑長。<br/>種籽的特色是討論文化、課程選修和師生平權的生活公約。<br/>社群教師朱佳仁曾任種籽理化與數學老師，以及苑長。'
      },
      {
        from: new Date(1998, 8),
        title: '台北市自主學習實驗計畫',
        description:
          '<img class="history" src="./img/auto.png"/>台北市唯一一次公民合作辦理的國、高中六年一貫自主中學實驗計畫。由社群教師李雅卿主持。<br/>社群教師戎培芬、朱佳仁、陳家帶、唐光華都在自主中學計畫任教多年，陪伴中學生從他律到自律。朱靜芬老師也曾在自主中學開設美術課程。<br/>實驗計畫透過必修、選修和自主三個學程，讓不同性向的學生都能發展特長，找到自己的目標和未來，也證明了自主學習的理念與作法，在中學階段可以有非常大的力道。<br/>自主中學實驗計畫經三次評鑑優良，唯因政府政策考量，只招三屆學生。'
      },
      {
        from: new Date(2000, 8),
        title: '自主中學實驗計畫面臨停辦',
        description:
          '<img class="history" src="./img/auto.png"/>2000年自主中學實驗計畫面臨停辦，引起國內理念學校嘩然，國際另類教育亦發起熱烈的聲援。<br/>為了挽救學校，自主中學的學生發起近年來台灣第一次中學生自發的學運，以和平理性的方式連署、義演，改變了媒體和大眾的誤解，也爭取到從北政國中遷至景文高中，遷校續辦至第三屆學生畢業為止的三屆八年。<br/>社群教師唐宗浩、高政宇都曾參與救校運動。當時他們還是國三和國一學生。<br/>確定台北市自主學習實驗計畫會被停止之後，為了延續自主學習的經驗與知識，讓社會各界了解「自主學習」的教育理念，與真正使人蛻變的作法，當時的親、師、生結合學術界的專家學者，於民國 90年3月30日召開會議，籌設「中華民國自主學習促進會」。<br/>同年8月9日，經內政部核准生效。實驗計畫結束後，由本會持續推動相關理念與作法。'
      },
      {
        from: new Date(2006, 8),
        title: '自學中心的點狀課程與營隊',
        description:
          '<img class="history" src="./img/autolearn.jpg" />本會「自學中心」曾透過課程、俱樂部和假期營隊，讓體制內的學生體驗自主和創造，認識學問的內涵與美。<br/>學生雖然在我們這邊開啟了學問的視野與創造的可能，但我們也注意到學校的考試壓力不斷拉扯，家長、學生總是疲於應付。<br/>本會「家學中心」同時發現，台灣推動在家自學的困境在中學。因為中學的學科開始分化，單靠家長教學，很難因應學生的學習需求。<br/>因此，本會決定於2008年轉型，直接面對想要學習自學、不惜離開學校的學生，無論他過去有多大的困難。'
      },
      {
        from: new Date(2008, 8),
        title: '自主培力學園',
        description:
          '<img class="history" src="./img/auto_empower.jpg" />針對體制內拒學、懼學和想自學的青少年，以一年的時間，透過一週五天，全日制的團體學習，來瞭解自己，重建學習興趣和習慣，重新發展人際關係。<br/>學園學生個別差異很大，每個人的優勢與困難也不相同。不過，總能在親師生的協同努力下，有所突破，讓自信和笑容重新回來。<br/>學園兩年，我們幫助了一些青少年和家庭，突破原本已被放棄的障礙。<br/>也幫助了一些沒有經驗過自學的人，瞭解自己，踏上自學之路。<br/>可惜因本會場地屬早期建築，無法取得建照，當政府公告團體自學申請辦法後，學園因此停止運作。'
      },
      {
        from: new Date(2009, 8),
        title: '獨立教育工作者社群',
        description:
          '<img class="history" src="./img/id_edu.jpg" />學園決定停招的那個學期，教師團決定不再依賴機構，成為獨立教育工作者。<br/>一方面運用過去累積的經驗，開設學習團體，陪伴自學家庭，通往自學之路。<br/>二方面傳承給有志教育的新夥伴，分享自主學習的教學經驗，開發自學教材。<br/>三方面與會務結合，聯結相關友會，開拓台灣自學資源，讓學習得到真正的解放。<br/>社群將教師培訓、家長成長列入重要任務，以正在自學的家庭及想要轉型自學的家庭為主要服務對象，創造親師生分享共榮的社群。<br/>本會及獨立教育工作者社群均為跨區域的全國性組織，期盼得到政府與民間各方面支持，發展具體可行的自主學習教育方向與制度，實踐推廣自主學習的教育型態，為我國教育改革提供新方向，注入新活力！'
      },
      {
        from: new Date(2011, 1),
        title: '自主學習教師/家長聯合動力營',
        description:
          '<img class="history" src="./img/id_edu.jpg" />時間：自2011年2月14日起至5月2日止，每週一下午2:00-4:30<br/>帶領義工：李雅卿<br/>此為獨立教育工作者自發的成長團體，目的在藉由同理回饋的戲劇方式，培養個人的同理能力與團隊的合作能力，分享彼此的教學經驗，創造協同成長的教師探索團體。<br/>因此表演不是目的，最後的演出與否，由參與人共同決定。<br/>動力營一內容如下：<br/>（一）相見歡<br/>（二）觀察、反應與自信<br/>（三）肢體、聲音、表情的基本戲劇訓練<br/>（四）身體雕塑、合作雕塑、與情緒同理的流動雕塑<br/>（五）情感的對比與回饋一／一對對的表現型式<br/>（六）情感的對比與回饋二／一頁頁的表現型式<br/>（七）抽象的同理表現型式：四元素<br/>（八）合作創造的同理呈現：自由表現<br/>（九）演出準備<br/>（十）公演（或不公演）<br/>社群教師蘇昭蓉、謝姍姍在此階段加入。'
      },
      {
        from: new Date(2011, 8),
        title: '獨立教育工作者社群教師動力營二',
        description:
          '<img class="history" src="./img/id_edu.jpg" />時間：自2011年9月20日起至12月27日止，​除2nd Tue之外的每週二下午2:00-4:30<br/>帶領義工：李雅卿<br/>動力營二的主軸是建立討論文化，與動力營一的同理能​力彼此獨立，相輔相成。<br/>自主學習的老師，一定要有良好的討論能力，才​能在親子直線關係外，加入善意第三人的專業觀點，陪伴家​庭，順利走在自學的道路上。<br/>良好的討論能力，來自對生命的深刻觀照，對多元文化的反省​與包容。至於一般人以為的溝通技巧，只是「術」，而非「道」。<br/>此次研習將陪大家把討論文化好好整理整理，一方靣​歡迎新成員，二方面對動力營一的夥伴們，有學習和釐清的價值。<br/>三方面，讓大家體驗一下，除了戲劇之外，思想的交​流，可以如何激發一個人的內在動力，並在團隊中建立起深​沈的信任與合作。<br/>動力營二內容如下：<br/>（一）生命觀與價值觀的檢視<br/>（二）消極聆聽與積極聆聴練習<br/>（三）同理的力量<br/>（四）界限概念<br/>（五）討論要素總整理<br/>（六）原生家庭檢視（家庭圖製作與分享）<br/>（七）探視負向情緒與操控誘惑<br/>（八）討論式的課堂經營<br/>（九）自助助人的工具與視野<br/>（十）其它一切想談的~~'
      },
      {
        from: new Date(2011, 8),
        title: '雲端化後的促進會',
        description:
          '<img class="history" src="./img/id_edu.jpg" />2011年九月一日促進會雲端化之後，由網路協力、志工認領的方式持續運作，維持e-mail、電話諮詢服務、開放教材的流通、自學地圖的網站以及對於自學相關修法與政策的持續關注。'
      }
    ]
  })
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

a {
  font-size: 1.5rem !important;
}

#main {
  width: 66%;
  height: 400px;
}

.timeline {
  max-width: 720px !important;
  margin: 0 auto;
}

@media screen and (max-width: 420px) {
  .timeline {
    position: relative;
    left: -2em;
  }
}

</style>
