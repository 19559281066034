<template lang="pug">
  .hello
    vue-headful(title="學習自律四階段 - 自學地圖", description="從他律到自主的過程，一般會經過解放散漫、自我中心、眼高手低和自主成熟這四個階段。")
    .ui.segment.container
      h1 學習自律四階段

      p 從他律到自主的過程，一般會經過解放散漫、自我中心、眼高手低和自主成熟這四個階段。

      p 在解放散漫期需要安全感和逐漸健康的生活節奏、自我中心期需要外在明確界限、眼高手低期需要踏實的累積與持續創作。

      p 這些都是需要成人介入引導與提點的，兒童與青少年，若任其自生自滅，往往不能自己走過自主四階段。

      p 因此，本會致力於促進自主學習與教育的土壤。

      p 一般說來，要從他律進到自律，多半會產生學習困惑、自我放逐、概念重建的內在革命歷程。

      p 每個階段所花的時間端看孩子的情況和家庭的對應而定，其中父母的態度，有關鍵性的影響。

      p 一般說來(實際情況因人而異)，他律到自律過程，在親師生協同的良好對應下，前兩個階段大約半年到一年左右，第三階段大約一到兩年，最後自主成熟，則是一輩子的美好。

      h2 解放散漫期(約0.5~1年)

      p ──剛開始的第一階段，孩子可能呈現放鬆、晃盪、不上課、骯髒、玩不夠、粘人、食量大、多話、嗜睡、不願意嘗試新事物等情況。如果家裡要求得嚴，孩子對課業還會有個假裝在意的「偽裝期」。不過撐不久。

      img(src="../assets/Amiba.jpg")

      h3 原因探討：

      p 離從他律狀態離開時，內在能量還很不穩定和虛弱，其實是需要休養生息，才有力氣好好學習。不可能馬上變得獨立自主、自動自發。養傷和回復，視之前累積耗損的嚴重程度、和家庭與環境的支持好壞，而需要或長或短，不同的時間。

      h3 親子對應要訣：

      p 身體的健康、規律的節奏最重要，學科的期待可以先放掉。

      p 應確保：有好好吃、好好運動、好好睡眠，讓能量補充，就可以安然渡過解放期。

      p 應避免：熬夜、睡前從事太剌激的活動、不運動、電動成癮、菸酒癮、毒癮。


      h3 師生對應要訣：

      p 首重正向支持氣氛和親師協同，學科的期待可以先放掉。

      p 應確保：正向支持氣氛。秩序的明確底限。

      p 應避免：坐視孩子受到霸凌、坐視孩子危險沒意識產生的安全問題。

      h2 自我中心期(約0.5~1年)

      p ──第二階段，愛頂嘴、自我中心、自私自利、挑剔父母、挑戰權威、容易憤怒、不守秩序。自己身體回復得不錯，卻常常不自覺地，造成別人的困擾、疲累、或是傷害。

      img(src = "../assets/shark.png")

      h3 原因探討：

      p 身心的內在力量回復了，終於有力氣往外擴展，但是還不清楚人我的分際，以及各種社會情境與自然情境的邊界。

      p 靠著外推來試探別人和世界的邊界。透過碰到邊界，來建立安定感。

      h3 親子對應要訣：

      p 不能放任孩子，也不能替孩子擋掉衝撞世界產生的自然結果。有時需要建立人為的邊界，讓孩子透過碰到邊界，建立安定感。

      p 應確保：秩序的明確底限。成人自己感受的明確表達。堅定感。

      p 應避免：討價還價、不斷退讓、搖擺不定。


      h3 師生對應要訣：

      p 自我中心期的孩子會過度拉走你的注意力，越界，或是不管別的同學的需求，要你只幫助他。這些情況超過了一個界限後，就必須明確拒絕。同時可以透過角色扮演、設身處地的引導，培養孩子的同理心。

      p 應確保：秩序的明確底限。成人自己感受的明確表達。堅定感。

      p 應避免：坐視孩子霸凌別人、搖擺不定。


      h2 眼高手低期(約1~2年)

      p ──第三階段是眼高手低期。開始知道自己的方向和目標，但是發現自己的能力和目標非常遠。想得到卻做不到，也對於如何建立自己的學習習慣、克服慣性容易感到「我知道我應該，可是我很難去做」的挫折。

      img(src="../assets/depressed.png")

      h3 原因探討：

      p 當孩子確立了邊界，知道自己不是無所不能，需要學習和成長才能達成目標，會一下子感受到自己的能力其實很小。


      h3 親子對應要訣：

      p 很多孩子在這時候會出現明顯低落的自我認知，和停滯的學習進程。有些父母因此著急並放棄陪孩子學習自律，送回體制學校或機構。

      p 其實，當孩子經歷到第三階段，就會羽化之前的蛹期，只要成人清楚、溫暖的接納，陪孩子一起找尋適切方法來支持，要啟動正向轉習循轉就不遠了。

      p 有些父母會熬不過這些階段，應付不了自己心理的矛盾或是親友的壓力，中途撤退，也有些父母一開始抱著趕流行的心態，等到孩子的轉型開始後，又覺得後悔，這樣的搖擺，對孩子其實是有傷害的。

      p 在蛹期，要看到孩子能夠瞭解自己的「眼高手低」，就是代表他的自覺出來了，是好事，可以陪他從基礎開始，一步一步把手練高。

      p 應確保：陪孩子把他們目標切細，用小階梯的方式，一步一腳印，逐步實現目標，累積踏實的成就感。

      p 應避免：過度否定孩子、用更大的目標來指責孩子為什麼達不到。

      h3 師生對應要訣：

      p 應確保：小階梯教學、明確診斷出學習的問題點加以支援、分享自己的習慣建立經驗。

      p 應避免：放低目標、不具體的安慰、唬弄學生。

      h2 自主成熟期(一輩子)

      img(src = "../assets/blind.png")

      p ──第四階段之後，孩子對人對事的包容力都會增加，有自信、有光彩、講道理、守規距也知變通。

      p 因為第四階段的美好，常讓人嚮往自己的孩子如果也能如此多好。

      p 但是自主學習，是從學習自主開始的。

      p 學習自主，不只是孩子的事，也是家長和所有協力的教師都要經歷的過程。

      p 兩到四年的過程，造就一輩子的獨立自主，這樣想想，認真陪伴自己、陪伴孩子渡過散漫的解放期、討厭的自我中心期、挫折的眼高手低期，也就值得了吧！
 
</template>

<script>
export default {
  name: 'auto4',
  data () {
    return {
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

img {
  max-height: 33vh;
}

</style>
